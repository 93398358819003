<template>
  <v-container>
    <v-alert type="error"
      >Su usuario no tiene permisos para usar esta aplicación. <br />
      Consulte con el administrador del sistema para más información.</v-alert
    >
  </v-container>
</template>

<script>
export default {
  name: "PermisosError",

  data() {
    return {};
  },
};
</script>
